import React from 'react'
import PropTypes from 'prop-types'
// redux is unused now
// import ReduxProvider from '@providers/ReduxProvider'

const MainProvider = ({ element }) => <>{element}</>

MainProvider.propTypes = {
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
}

export default MainProvider
